<template>
  <div v-loading="loading">
    <el-row>
      <el-col>
        <el-date-picker
          v-model="selectDateValue"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>

        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>

        <!-- <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
        >
          导出表格
        </el-button> -->
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="box">
          <h1>{{ initData.WorkPerforTotal }}</h1>
          <h4>劳动业绩</h4>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="box">
          <h1>{{ initData.WorkPackPerforTotal }}</h1>
          <h4>疗程劳动业绩</h4>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box df-box">
          <div>
            <h1>{{ initData.CashPayTotal }}</h1>
            <h4>实收现金</h4>
          </div>
          <div>
            <div class="number">
              <ul v-if="initData.CashPayments">
                <li v-for="(item, index) in initData.CashPayments" :key="index">
                  {{ item.Name + ": " + item.Amount }}
                </li>
                <li>{{ "日常支出: " + initData.SpendExpense }}</li>
                <li>{{ "日常收入: " + initData.SpendIncome }}</li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="box" v-if="initData.OpenAndKeepData">
          <h1>{{ initData.OpenAndKeepData.TotalPerfor }}</h1>
          <h4>开充卡业绩</h4>
          <div class="card">
            <p>储值卡业绩：{{ initData.OpenAndKeepData.OpenPerfor }}</p>
            <p>充值业绩: {{ initData.OpenAndKeepData.KeepPerfor }}</p>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="box">
          <h1>{{ initData.OpenPackCardPerforTotal }}</h1>
          <h4>开疗程卡业绩</h4>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box df-box" v-if="initData.ClientCategoryData">
          <div>
            <h1>{{ initData.ClientCategoryData.TotalPerson }}</h1>
            <h4>客流分析</h4>
          </div>
          <div class="df-more">
            <div class="number">
              <ul>
                <li>男客数：{{ initData.ClientCategoryData.MalePerson }}</li>
                <li>
                  会员客数: {{ initData.ClientCategoryData.MemberPerson }}
                </li>
                <li>
                  指定客数: {{ initData.ClientCategoryData.AssignPerson }}
                </li>
                <li>
                  现金客数: {{ initData.ClientCategoryData.CashPayPerson }}
                </li>
                <li>
                  其他付款: {{ initData.ClientCategoryData.OtherPayPerson }}
                </li>
              </ul>
            </div>
            <div class="number">
              <ul>
                <li>女客数: {{ initData.ClientCategoryData.FemalePerson }}</li>
                <li>
                  散客数: {{ initData.ClientCategoryData.NotMemberPerson }}
                </li>
                <li>
                  非指定客数: {{ initData.ClientCategoryData.NotAssignPerson }}
                </li>
                <li>
                  卡扣客数: {{ initData.ClientCategoryData.CardPayPerson }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="box">
          <h1>{{ initData.NewMemberCount }}</h1>
          <h4>新开发会员</h4>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="box">
          <h1>{{ initData.SellPerforTotal }}</h1>
          <h4>外卖产品</h4>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import api from "@/api/report.js";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      initData: {},
      submitData: {
        begin_date: "",
        end_date: "",
      },
      selectDateValue: [],
      loading: false,
      excelDisabled: false, // 表格按钮禁用
    };
  },

  async mounted() {
    await this.initDateState()
    await this.initPageData();
  },

  methods: {
    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.businessSummary(this.submitData);
        this.initData = data;
        console.log(data);
      } catch (e) {}
      this.loading = false;
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch(
        "onGetDateState"
      );
      this.selectDateValue = [beginDay, endDay];
      this.submitData.begin_date = beginDay;
      this.submitData.end_date = endDay;
    },

    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", {dayTime: event});
      this.initDateState()
    },

    onSearch() {
      this.initPageData();
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.$nextTick(async () => {
        try {
          // let tableArray = [
          //   ['']
          // ]

          // this.selectDateValue.forEach(item=>{
          //   tableArray
          // })

          // return console.log(this.selectDateValue);

          try {
            //创建工作簿
            var workbook = XLSX.utils.book_new();
            // var worksheet = XLSX.utils.json_to_sheet(this.tableData,{header:['A','B','C'],skipHeader:true})
            var ws = XLSX.utils.json_to_sheet(
              [
                {
                  S: { t: "n", v: 1111, s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
                  h: 2,
                  e: 3,
                  e_1: { t: "n", v: 999, s: { r: 1, c: 4 }, e: { r: 1, c: 6 } },
                  t: 5,
                  J: 6,
                  S_1: 7,
                },
              ],
              { header: ["S", "h", "e", "e_1", "t", "J", "S_1"] }
            );
            console.log(ws);
            //工作簿、工作表、工作表名
            XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
            let wbout = XLSX.writeFile(workbook, "测试111.xlsx");
          } catch (err) {
            typeof console !== "undefined" ? console.log(err, wbout) : null;
          }
          // console.log(dom);
        } finally {
          this.excelDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin-top: 20px;

  .el-button {
    margin-left: 10px;
  }

  .el-date-editor {
    max-width: 350px !important;
  }

  .box {
    height: 200px;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 5px;
    h4,
    h1 {
      margin: 20px;
    }
    h4 {
      color: #0ae;
    }
    h1 {
      font-size: 34px;
    }

    .card {
      margin: 0 20px;
      // color: #999999;
    }
  }

  .box:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .df-box {
    display: flex;
    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      flex: 2;
    }

    .df-more {
      display: flex;
      > div {
        flex: 1;
      }
    }

    .number {
      height: 200px;
      // color: #999999;

      ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        padding: 20px;
        box-sizing: border-box;

        li {
          margin: 5px 0;
        }
      }
    }
  }
}
</style>
